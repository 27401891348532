@font-face {
  font-family: 'icon';
  src:  url('../../public/fonts/icon.eot?ss5keq');
  src:  url('../../public/fonts/icon.eot?ss5keq#iefix') format('embedded-opentype'),
    url('../../public/fonts/icon.ttf?ss5keq') format('truetype'),
    url('../../public/fonts/icon.woff?ss5keq') format('woff'),
    url('../../public/fonts/icon.svg?ss5keq#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"]::before, [class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e900";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-chevron-left:before {
  content: "\e902";
}
.icon-chevron-right:before {
  content: "\e903";
}
.icon-chevron-up:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e905";
}
.icon-envelope:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-file-pdf:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-location-dot:before {
  content: "\e90a";
}
.icon-minus:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-sort-up:before {
  content: "\e90f";
}
.icon-square-caret-up:before {
  content: "\e910";
}
.icon-youtube:before {
  content: "\e911";
}
