/* @import url(https://fonts.googleapis.com/css?family=Noto Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic); */
/* @import url(https://fonts.googleapis.com/css?family=Noto Sans:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic); */
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,300italic,regular,italic,500,500italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

* {
  padding: 0;
  margin: 0;
  border: 0;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul,
ol {
  padding-left: 0;
  margin-bottom: 0;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select::-ms-expand {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: none !important;
}
img {
  width: 100%;
  object-fit: cover;
}
ul ul {
  margin: 4px 0 5px 20px;
}
body {
  font: 400 14px/1.45 Noto Sans;
  color: #505050;
}

h2 {
  font-size: 24px;
}
h1 {
  font-size: 28px;
}
.wrapper {
  min-height: 100vh;
}
.wrapper-page {
  padding: 20px 0 40px 0;
}
.btn-main,
.loadmore {
  display: inline-block;
  font: 700 14px/1.15 Noto Sans;
  color: #fff !important;
  text-transform: uppercase;
  padding: 11px 30px;
  transition: 0.3s;
  margin-top: 10px;
}
.btn-main:hover {
  background-color: #b0b0b0;
}
.btn-main {
  background: #333;
}
.loadmore {
  background: #b0b0b0;
}
.loadmore:hover {
  background: #333;
}
.title {
  position: relative;
  font-size: 26px;
  color: #151515;
  margin: 0;
  text-transform: none;
  position: relative;
  padding-bottom: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
.title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  height: 2px;
  width: 40px;
  background: #ccc;
}
.label {
  color: #151515;
  color: #505050;
  margin-bottom: 15px;
  text-transform: uppercase;
  font: 600 18px/1.15 Noto Sans;
  text-align: left !important;
}
input {
  border: 1px solid #1c1d1d;
  max-width: 100%;
  padding: 8px 10px;
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 22px;
  }
}
.text {
  line-height: 27px;
  font-size: 18px;
}
.text ul li {
  margin-bottom: 5px;
}
.text ul li:last-of-type {
  margin-bottom: 0;
}
@media screen and (max-width: 850px) {
  .text {
    font-size: 16px;
    line-height: 25px;
  }
}
.row-2 {
  display: flex;
  align-items: center;
  margin: 0 -15px;
}
.col-2 {
  width: 50%;
  padding: 0 15px;
}
@media screen and (max-width: 850px) {
  .row-2 {
    flex-wrap: wrap;
    margin: 0;
  }
  .col-2 {
    width: 100%;
    padding: 0;
  }
  .col-2:first-of-type {
    margin-bottom: 20px;
  }
}

.pt-100 {
  padding-top: 80px;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
}
.container {
  max-width: 95%;
  width: 1200px;
  margin: 0 auto;
}
.header__wrapper {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  height: 70px;
  position: relative; */
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo {
}
.header__logo img {
  height: 60px;
  object-fit: cover;
}
.header__menu {
  display: flex;
  align-items: center;
  height: 100%;
}
.header__menu > li {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.header__menu > li:last-of-type {
  margin-left: 0;
}
.header__menu li a,
.header__menu li p {
  text-transform: uppercase;
  color: #1d1d1d;
  font-style: normal;
  color: #505050;
  font: 400 18px/1.45 Noto Sans;
  padding: 10px 5px;
  margin-bottom: 0;
}

.header__menu li span {
  /* margin-left: 10px; */
  font-size: 12px;
}
.opened {
  position: relative;
  height: 100%;
}
.dropdown-menu1 {
  top: 120%;
  left: -40%;
  /* transform: translateY(0); */
}
.dropdown-menu2 {
  top: 20%;
  left: 92%;
  /* transform: translateY(0); */
}
.dropdown-menu1,
.dropdown-menu2 {
  position: absolute;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.09);
  transform: translate3d(0px, -12px, 0px);
  transition: 0.3s;
  pointer-events: none;
  min-width: 250px;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
}
.dropdown-menu1 li,
.dropdown-menu2 li {
  padding: 4px 20px;
}
.dropdown-menu1 li a,
.dropdown-menu2 li a {
  font-style: normal;
  color: #505050;
  font: 400 16px/1.45 Noto Sans;
  text-transform: capitalize;
}
.dropdown-menu1 li a:hover,
.dropdown-menu1 li a:focus,
.dropdown-menu1 li a:active,
.dropdown-menu2 li a:hover,
.dropdown-menu2 li a:focus,
.dropdown-menu2 li a:active,
.header__menu li a:hover,
.header__menu li a:focus,
.header__menu li a:active {
  color: #b0b0b0;
  text-decoration: none;
}
.opened-sub a span {
  margin-left: 5px;
}
.opened-sub {
  position: relative;
  height: 100%;
}

.opened:hover .dropdown-menu1 {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(-20px);
}
.opened-sub:hover .dropdown-menu2 {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(-20px);
}
.header__operations {
  display: flex;
  align-items: center;
}
.header__language {
  min-width: 63px;
}
.header__language button,
.header__language .dropdown-item {
  text-transform: uppercase;
}
.header__search {
  font-size: 20px;
  cursor: pointer;
  margin-left: 20px;
}
.header__search-input {
  display: none;
  position: absolute;
  top: 80%;
  right: 0;
}
.header__search-input.show {
  display: block;
}
.header__search-input input {
  border: 1px solid #150d46;
  padding: 10px;
  border-radius: 10px;
  width: 300px;
}
.header__search-input input::placeholder {
}
.header__search-input span {
  position: absolute;
  top: 30%;
  right: 7%;
}
.header__mobile {
  display: none;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1050px) {
  .header__menu > li {
    margin-left: 0px;
  }
  .header__menu li a {
    font-size: 16px;
  }
}
@media screen and (max-width: 945px) {
  .header__logo img {
    height: 40px;
    width: 37.5px;
  }
  .header__menu,
  .header__search,
  .header__search-input,
  .header__search-input.show {
    display: none;
  }
  .header__mobile {
    display: block;
    font-size: 20px;
    cursor: pointer;
  }
  .mobile {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 99999999;
  }
  .mobile.active {
    opacity: 1;
    visibility: visible;
    display: block;
  }
  .active {
    opacity: 1;
    visibility: visible;
  }
  .mobile__wrapper {
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px 10px;
    max-width: 95%;
    z-index: 10;
    color: #fff;
    background-color: #1c1d1d;
    width: 50%;
  }
  .mobile__close {
    padding: 30px 0 30px 20px;
    font-size: 34px;
    cursor: pointer;
  }
  .mobile__menu {
    margin-bottom: 50px;
  }
  .mobile > ul {
    padding-left: 20px;
  }
  .mobile__menu > li {
    text-transform: uppercase;
  }
  .mobile__menu li {
    padding: 10px 0;
    border-bottom: 1px solid #343535;
  }
  .accordion-header-sub {
    text-transform: capitalize;
  }
  .mobile__menu li:first-of-type {
    border-top: 1px solid #343535;
  }
  .dropdown-menu-mobile1 li:first-of-type {
    border-top: none;
  }
  .mobile__menu li:last-of-type {
    border-bottom: none;
  }
  .mobile__menu li a,
  .mobile__menu li {
    font-size: 16px;
    color: #fff;
  }
  .accordion-header,
  .accordion-header-head {
    display: flex;
    justify-content: space-between;
  }
  .mobile__search {
    position: relative;
  }
  .mobile__search input {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    height: 45px;
  }
  .mobile__search span {
    position: absolute;
    top: 30%;
    right: 3%;
  }
  .hero {
    padding-top: 80px !important;
  }
}
.modal {
  /* background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  overflow: scroll;
  overflow-x: hidden; */
}
.swiper-button-prev,
.swiper-button-next {
  background-color: #505050;
  width: 40px !important;
  height: 40px !important;
  border-radius: 10px;
  transition: 0.3;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px !important;
  color: #fff;
  font-weight: 800;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #000000;
}
.swiper-button-next {
}
.hero {
  height: 100vh;
  padding-top: 80px;
}
.hero__swiper {
  position: relative;
}
.hero__swiper .swiper-slide,
.hero__img {
  height: 90vh;
  /* width: 100%; */
}

.hero__info {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  text-align: center;
}
.hero__title {
  font-size: 36px;
  white-space: normal;
  text-transform: uppercase;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .hero__info {
    width: 70%;
  }
  .hero__title {
    font-size: 28px;
  }
}
@media screen and (max-width: 426px) {
  .hero__title {
    font-size: 22px;
  }
}

.about {
}
.about__wrapper {
}
.about__title {
}

.about__row {
}
.about__col {
}

.about__img {
}
.about__img img {
  width: 100%;
  object-fit: cover;
  height: 500px;
}
.about__info {
}

.about__btn {
}

@media screen and (max-width: 850px) {
  .about__img {
    margin-bottom: 20px;
  }
  .btn__read {
    width: 100%;
    text-align: center;
  }
}
.popular {
}
.popular__wrapper {
}
.popular__title {
}
.product__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.product__col {
  padding: 0 10px;
}
.card-4 {
  width: 25%;
}
.product__item {
  background: #fff;
  border: 1px solid #ebebeb;
  padding: 15px;
  margin-bottom: 20px;
}
.product__img {
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product__img img {
  height: 208px;
  object-fit: cover;
}
.product__body {
  height: 103px;
}
.product__name {
  font: 400 18px/1.15 Noto Sans;
  color: #2b3743;
  height: 38px;
  display: inline-block;
  overflow: hidden;
  transition: 0.3s;
  margin: 20px 0;
}
.product__name:hover {
  color: #b0b0b0;
}
.product__price {
  font: 400 17px/1.15 Noto Sans;
  margin-bottom: 20px;
}
.product__price-old {
  color: #999;
  text-decoration: line-through;
  margin-right: 10px;
}
.product__price-new {
}
.popular__btn {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.card-3 {
  width: 33.333%;
}
.tab__subcategory {
  width: 100%;
}

@media screen and (max-width: 1170px) {
  .tab-subcategory p {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1130px) {
  .card-3 {
    width: 50%;
  }
}
@media screen and (max-width: 1000px) {
  .product__col {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .card-3 {
    width: 100% !important;
  }
  .tab-categories p {
    font-size: 16px !important;
  }
  .tab-subcategory p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 500px) {
  .product__row {
    margin: 0px;
  }
  .product__col {
    width: 100%;
    padding: 0px;
  }
}
.solution {
}
.solution__wrapper {
}
.solution__title {
}
.solution__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.solution__col {
  width: 33.333%;
  padding: 0 5px;
}
.solution__item {
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.solution__icon {
  margin-bottom: 20px;
  height: 68px;
  width: 68px;
}
.solution__icon img {
  min-height: 64px;
}
.solution__label {
}

.solution__info {
  line-height: 22px;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .solution__col {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .solution__row {
    margin: 0;
  }
  .solution__col {
    width: 100%;
    padding: 0;
  }
}
.project {
}
.project__row {
  display: flex;
  margin: 0 -15px;
}
.project__col {
  width: 33.333%;
  padding: 0 15px;
}
.project__item {
  position: relative;
}
.project__img {
}
.project__img img {
  width: 100%;
  object-fit: cover;
  min-height: 289px;
}
.project__text {
  position: absolute;
  width: 100%;
  height: 69px;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 220px;
  text-indent: 20px;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  transition: 0.3s;
}
.project__text:hover {
  color: #aad6ef;
}

.project__name {
  /* margin: 0; */
  text-align: center;
}
.project__icon {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .project__row {
    flex-wrap: wrap;
    margin: 0px;
  }
  .project__col {
    width: 100%;
    padding: 0px;
  }

  .project__item {
    margin-bottom: 15px;
  }
}

.service {
}
.service__wrapper {
}
.service__title {
}
.service__swiper {
}
.service__item {
  border: 1px solid #ebebeb;
  padding: 15px;
  text-align: center;
}
.service__img {
}
.service__body {
}
.service__label {
  color: #151515;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  display: block;
  margin-bottom: 15px;
}
.service__text {
  font-size: 16px;
  line-height: 24px;
}

.news {
}
.news__wrapper {
}
.news__title {
}
.news__swiper {
}

.news-page__item:hover .news__img img {
  transform: scale(1.1);
}

.news__img {
  overflow: hidden !important;
  display: block;
}

.news__img img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  height: 245px;
  object-fit: cover;
  transition: transform 0.3s; /* Перенес transition внутрь этого правила */
  transform: scale(1);
}

.news__img:hover img {
  transform: scale(1.1);
}

.news__body {
  text-align: center;
  padding: 25px 20px;
  /* margin-top: 20px; */
}
.news__label {
  height: 40px;
  display: block;
  margin: 0 0 10px;
  overflow: hidden;
}
.news__date {
  display: flex;
  justify-content: center;
  color: gray;
  font-size: 13px;
}
.news__date span {
  margin-right: 10px;
}
.news__text {
  font-size: 16px;
  line-height: 24px;
  height: 45px;
  display: block;
  overflow: hidden;
  text-align: left;
}
.news__btn {
  display: block;
  text-align: left;
}
.news__btn i {
  margin-left: 5px;
  font-size: 12px;
}
.contact {
  padding: 100px 0 80px 0;
}

.contact__wrapper {
}
.contact__title {
}
.contact__row {
}
.contact__col {
}

.contact__img img {
  width: 100%;
  object-fit: cover;
  height: 500px;
}
.contact__info ul li span {
  margin-right: 7px;
}

.footer {
}
.footer__top {
  background: #191919;
  color: #b7b7b7;
  font-size: 14px;
  padding: 50px 0;
}

.footer__item {
}
.footer__title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
  padding-left: 15px;
  font-size: 15px;
  position: relative;
}
.footer__title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: #349fe2;
}
.footer__content {
  color: #b7b7b7;
  margin-bottom: 20px;
}
.footer__content span {
  /* margin-right: 15px; */
}
.footer__social {
  display: flex;
}
.footer__social li {
  margin-right: 10px;
}
.footer__social li a {
  background: #303030;
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 3px;
}
.footer__contact {
}
.footer__contact li a {
  color: #b7b7b7;
  margin-bottom: 20px;
}
.footer__address li span,
.footer__contact li span {
  margin-right: 10px;
  color: #fff;
}
.footer__address li,
.footer__contact li {
  margin-bottom: 10px;
}
.footer__address li:last-of-type,
.footer__contact li:last-of-type {
  margin-bottom: 0;
}
.footer__bottom {
  color: #fff;
  background: #222;
  padding: 20px 0;
}
.footer__copyrights {
  text-align: center;
}
.f-w {
  flex-wrap: wrap;
}
.f-w .col-3 {
  margin-bottom: 20px;
}
.row-3 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.col-3 {
  padding: 0 20px;
  width: 33.333%;
}
@media screen and (max-width: 768px) {
  .row-3 {
    flex-wrap: wrap;
    margin: 0px;
  }
  .col-3 {
    padding: 0px;
    width: 100%;
    margin-bottom: 10px;
  }
}
.collection {
  padding-top: 100px;
}
.breadcrumb__top {
  border: none;
  background: #f7f7f7;
  padding: 15px 0;
  margin-bottom: 0px !important;
}
.breadcrumb__title {
  text-transform: uppercase;
  color: #151515;
  display: block;
  font-size: 22px;
  font: 600 22px/1.15 Noto Sans;
  margin: 20px 0 15px 0;
}
.breadcrumb-item a {
}
.breadcrumb-item.active {
  color: #262525;
  font-size: 16px;
}
a {
  font-size: 16px;
  color: #505050;
  transition: 0.3s;
}
a:hover {
  opacity: 1;
  color: #b0b0b0;
}
.nav-item button {
  color: #505050 !important;
  font: 400 16px/1.15 Noto Sans;
  transition: 0.3s;
  padding: 14px;
}
.nav-item button:hover {
  opacity: 1;
  color: #b0b0b0 !important;
}
.pagination {
  font-family: Noto Sans;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;
  padding: 20px 0;
  text-align: center;
  font-size: 1em;
}
.page-item.active {
  color: #b0b0b0;
  background-color: transparent;
}
.page-link {
  background-color: transparent !important;
  border: none;
  font-size: 16px;
}
.page-item.active .page-link {
  color: #b0b0b0;
}
.page-item .page-link {
  color: #505050;
  transition: 0.3s;
}
.page-item .page-link:hover {
  color: #b0b0b0;
}
.details {
  padding-top: 100px;
}
.details__wrapper {
}
.details__top {
  display: flex;
  margin: 0 -20px;
}
.details__col {
  padding: 0 20px;
}
.details__col-img {
  width: 40%;
}
.details__col-info {
  width: 60%;
}
.details__img {
}
.details__img img {
}
.details__item {
  display: flex;
  justify-content: space-between;
}
.details__info {
  width: 62.5%;
}
.details__price {
  margin-bottom: 20px;
  color: #333;
  letter-spacing: 0;
  font: 600 30px/1.15 Noto Sans;
}
.details__description {
}
.details__description li {
  list-style: square inside;
  margin-bottom: 5px;
  font-size: 18px;
}
.details__pdf {
  width: 37.5%;
  margin-left: 20px;
}
.download-pdf {
  font: 600 24px/1.15 Noto Sans;
  color: #333;
}
.details__bottom {
}

@media screen and (max-width: 850px) {
  .details__top {
    flex-wrap: wrap;
    margin: 0px;
  }
  .details__col {
    padding: 0px;
  }
  .details__col-img {
    width: 100%;
    margin-bottom: 30px;
  }
  .details__col-info {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .details__item {
    flex-wrap: wrap;
  }
  .details__info {
    width: 100%;
  }
  .details__price {
    margin-bottom: 20px;
    color: #333;
    letter-spacing: 0;
    font: 600 30px/1.15 Noto Sans;
  }
  .details__description {
  }
  .details__description li {
    list-style: square inside;
    margin-bottom: 5px;
    font-size: 18px;
  }
  .details__pdf {
    width: 100%;
    margin: 15px 0 0 0;
  }
}

.image-carousel-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-container {
  position: relative;
}

.carousel-image-container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-image-container img {
  height: 500px !important;
  object-fit: cover !important;
}

.thumbnail-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.thumbnail-carousel {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.carousel-indicators button {
  background-color: #007bff !important;
}

.thumbnail-image {
  width: 100px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border: 2px solid transparent;
  opacity: 0.6;
}

.thumbnail-image.active {
  opacity: 1;
  border-color: #007bff;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 10px;
}

.arrow:hover {
  background-color: #e5e5e5;
}

.arrow.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.alternative-image img {
  height: 400px;
  width: 400px;
}
.alternative-image-small img {
  height: 60px;
  width: 100px;
}

.about-page {
}
.about-page-wrapper {
}
.about-page__col {
}
.about-page__item {
}
.about-page__img {
}
.about-page__img img,
.contact-page__img img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  height: 400px;
}
.about-page__text ul li {
  list-style: square inside;
}
@media screen and (max-width: 600px) {
  .about-page__img img,
  .contact-page__img img {
    height: 350px;
  }
}

.news-page {
}
.news-page__wrapper {
}
.news-page__row {
}
.news-page__col {
}
.news-page__item,
.news__item {
  border: 1px solid #d9d9d9;
  border-radius: 25px;
  overflow: hidden;
}

.article {
}
.article__wrapper {
}
.article__date {
  margin: 0 0 10px;
}
.article__date span {
  text-transform: uppercase;
}
.article__img {
  margin-bottom: 20px;
}
.article__img img {
  max-height: 500px;
}
.article__text {
  margin-bottom: 20px;
}
.article__btn {
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  text-align: right;
}
.related {
}
.related__title {
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 2px solid #b0b0b0;
  font: 600 18px/1.15 Noto Sans;
  text-align: center;
  margin-bottom: 50px;
}

.search {
}
.search__wrapper {
}
.search__top {
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.search__title {
  font-size: 24px;
  color: #505050;
  position: relative;
  text-align: center;
  padding-bottom: 15px;
  font-weight: 600;
  margin-bottom: 50px;
}
.search__title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  height: 1px;
  width: 50px;
  background: #1c1d1d;
}
.search__input input {
  border-color: transparent;
  background-color: #f6f6f6;
  color: #1c1d1d;
  padding: 8px 10px;
  height: 45px;
  max-width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.search__input {
  display: flex;
}
.search__input input:focus {
  border: 1px solid #1c1d1d;
}
.search__input button {
  background: #b0b0b0;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.icon-search {
}
.search__result {
}
.search__row {
}
.search__null,
.not-found {
  text-align: center;
  font-size: 24px;
  width: 100%;
  font-weight: 600;
}
.not-found-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-found {
}

.collection__row {
  display: flex;
  width: 100%;
  margin: 0 -20px;
}

.collection__col {
  padding: 0 20px;
}
.col-subcategory {
  width: 20%;
}
.collection__subcategory {
}
.col-tab {
  width: 80%;
}
.collection__tab {
}
.tab-content {
  width: 80%;
}
.tab-pane > div {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.tab-content .tab-content > div {
  width: 100%;
}
.nav-pills {
  width: 20%;
}
.sub-category-tabs {
  display: flex;
}
.tab-pane .active {
  /* display: none; */
}
.fade .tab-pane.active.show {
  /* display: block; */
}
.tab-content > .active {
  display: none;
}
.tab-content > .active.show {
  display: block;
}
.tab-subcategory.active,
.tab-category.active {
  color: #483ca5;
}

.tab-top {
  background-color: #e6e7e8;
}
.tab-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}
.tab-categories p {
  margin-right: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}
.tab-categories p:last-of-type {
  margin-right: 0;
}

.tab__center {
  margin: 0 -20px;
}
.tab__col {
  padding: 0 20px;
}
.tab__center {
  margin-top: 30px;
}
.tab-subcategory {
  width: 20%;
}
.tab-subcategory p {
  /* margin-bottom: 20px; */
  border-bottom: #c8c8c8 solid 1px;
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 500;
}
.tab-subcategory p:last-of-type {
  margin-bottom: 0;
}
.tab-content {
  width: 80%;
}
.tab__row > div {
  padding: 0 20px;
}
p br {
  margin-bottom: 0 !important;
}
